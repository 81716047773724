export default class ConvertSpTestTransformer {
    public static transFormSpTestToConvV(sptestValue: string, conv_vValue: string | null): string {
        const [experimentId, variantId] = decodeURIComponent(sptestValue).split(':');
        if (!conv_vValue) {
            return this.#generateConvV(experimentId, variantId);
        }

        return this.#replaceConvVValues(experimentId, variantId, decodeURIComponent(conv_vValue));
    }

    static #generateConvV(experimentId: string, variantId: string): string {
        return `vi:1*sc:1*cs:${Date.now()}*fs:${Date.now()}*pv:1*exp:{${experimentId}.{v.${variantId}-g.{}}}`;
    }

    static #replaceConvVValues(
        experimentId: string,
        newVariantId: string,
        convValue: string
    ): string {
        const regex = new RegExp(`(${experimentId}\\.\\{v\\.)\\d+`, 'g');

        if (regex.test(convValue)) {
            return convValue.replace(regex, `$1${newVariantId}`);
        }

        const expIndex = convValue.indexOf('exp:{');

        if (expIndex !== -1) {
            return convValue.replace(
                /(\*exp:\{)(.*)(})/,
                `$1$2-${experimentId}.{v.${newVariantId}-g.{}}$3`
            );
        }

        return `${convValue}*exp:{${experimentId}.{v.${newVariantId}-g.{}}}`;
    }
}
