import { TrackingTimeout } from './GoogleAnalyticsEventsBase';
import { GoogleAnalyticsIdManager } from '../modules/app/GoogleAnalyticsIdManager';
import { getGoogleAnalyticsClientId, getGoogleAnalyticsSessionId } from '../util/google';

declare global {
    interface Window {
        dataTrackerUrl: string | undefined;
    }
}

enum BigQueryTypeLength {
    STRING_64 = 64,
    STRING_128 = 128,
    STRING_256 = 256
}

export type BigQueryEventParamsType = {
    eventKey: string;
    eventValue: string;
};

export type BigQueryDataType = {
    eventName: string;
    eventParams: BigQueryEventParamsType[];
};

type BigQueryRequestDataType = BigQueryDataType & {
    locale: string;
    pageLocation: string;
    sessionId: string;
    clientId: string;
};

export class BigQuery {
    #timeout: number | null = null;

    public async track(data: BigQueryDataType): Promise<void> {
        await this.#send(data);
    }

    public trackWithDelay(
        data: BigQueryDataType,
        delay: number = TrackingTimeout.SEARCH_DELAY
    ): void {
        if (this.#timeout !== null) {
            window.clearTimeout(this.#timeout);
        }

        this.#timeout = window.setTimeout(async (): Promise<void> => {
            await this.track(data);
        }, delay);
    }

    async #send(data: BigQueryDataType): Promise<void> {
        if (!window.dataTrackerUrl) {
            return;
        }

        try {
            const requestData: BigQueryRequestDataType = await this.#formatRequestData(data);

            await fetch(window.dataTrackerUrl, {
                body: this.#parseData(requestData),
                method: 'POST',
                headers: { 'Content-Type': 'text/plain' }
            });
        } catch (e) {
            //
        }
    }

    #parseData(data: BigQueryRequestDataType): string {
        return btoa(JSON.stringify(data));
    }

    async #formatRequestData(data: BigQueryDataType): Promise<BigQueryRequestDataType> {
        const clientId =
            (await getGoogleAnalyticsClientId()) ?? GoogleAnalyticsIdManager.UNKNOWN_VALUE;
        const sessionId =
            (await getGoogleAnalyticsSessionId()) ?? GoogleAnalyticsIdManager.UNKNOWN_VALUE;

        return {
            locale: this.#checkValueLength(
                document.documentElement.dataset.locale ?? '',
                BigQueryTypeLength.STRING_64
            ),
            pageLocation: this.#checkValueLength(
                window.location.href,
                BigQueryTypeLength.STRING_256
            ),
            sessionId: this.#checkValueLength(sessionId, BigQueryTypeLength.STRING_128),
            clientId: this.#checkValueLength(clientId, BigQueryTypeLength.STRING_128),
            eventName: this.#checkValueLength(data.eventName, BigQueryTypeLength.STRING_64),
            eventParams: data.eventParams.map(({ eventKey, eventValue }) => ({
                eventKey: this.#checkValueLength(eventKey, BigQueryTypeLength.STRING_64),
                eventValue: this.#checkValueLength(eventValue, BigQueryTypeLength.STRING_256)
            }))
        };
    }

    readonly #checkValueLength = (value: string, max: number): string => {
        if (value.length > max) {
            throw new Error(`Given value exceeds maximum: ${max}`);
        }

        return value;
    };
}

export default new BigQuery();
